import React, { useState, useContext, useEffect } from "react"
import { Toastify, Toast } from "../toastify/toastify"
import { url, } from "../../helpers/url"
import axios from "axios"
import { Context } from "../../helpers/context"
import ReactStars from "react-rating-stars-component"
import { SplideSlide } from "@splidejs/react-splide"
import CarouselAll from "../../components/carousel/all-carousel"
import "./business.css"
import { useHistory, Link } from "react-router-dom"
import { businessProfile } from "../../helpers/image_url"
import { substring } from "../../helpers/functions"

const turf = require("@turf/turf")
const dispensary = '/dispensary/'
const Business = ({ slider, data, page, title, cordinates, link, col = "col-lg-3" }) => {
	const history = useHistory()

	const [user, setUser] = useContext(Context)
	const [productItem, setProductItem] = useState([])

	useEffect(() => {
		setProductItem(data)
	}, [data])


	const sendFavorite = (e, arrId, product, title, path) => {
		if (e.target.className !== 'icon') {
			history.push(dispensary + path)
		} else {
			if (user.type) {

				productItem[arrId]["business_wishes.businessId"] = !productItem[arrId]["business_wishes.businessId"]
				setProductItem([...productItem])
				let notice = { url: dispensary + path, notice: title, notice_type: 'wish', type: 'customer' }
				if (!productItem[arrId]["business_wishes.businessId"]) {
					notice['notice_type'] = 'unwish'
					Toast("You have unwished")
				} else {
					Toast("You have wished")
				}

				axios.post(`${url}/wishlist/dispensary/api`, {
					businessId: product,
					url: productItem[arrId].url,
					mainUserId: productItem[arrId].userId,
					title: substring(productItem[arrId].title, 20)
				})
					.then(({ data }) => {
						if (data.notification) {
							user.socket.emit("addNotification", data.notification)
						}
					})
			} else {
				setUser({ ...user, loginPopup: true, pageYOffset: window.pageYOffset })
			}
		}

	}



	// kilometers 
	const options = { units: "miles" }

	const starController = {
		edit: false,
		half: false,
		count: 5,
		size: 22,
		isHalf: true,
		activeColor: '#EFC368'
	}

	const starClickHandler = (item) => {
		window.location.href = dispensary + item.url + '#starbox'
	}




	return (
		<>

			<div className="business">
				{/* It is for  slider */}
				<Toastify />
				{slider && (
					<div className="products">
						<CarouselAll page={4} title={title} link={link}>
							{productItem.map((item, i) => {
								if (item.latitude && item.longitude) {
									var from = turf.point([cordinates.lat, cordinates.lng])
									var to = turf.point([item.latitude, item.longitude])
									var distance = Math.round(turf.distance(from, to, options))
								}

								let profile = item.profile ? JSON.parse(item.profile) : businessProfile
								return (
									<SplideSlide>
										<div>

											<div className="brand_wrapper" onClick={(e) => sendFavorite(e, i, item.id, item.title, item.url, item.businessId)} id={"business-" + item.id}>
												<div className="main_img">
													<div className="brand_items">
														<div className="img_wrapper">
															<img src={profile.url} alt="" />
														</div>
														<div className="product_icon">
															<img className={"icon"} src={item["business_wishes.businessId"] ? url + '/uploads/default/heart-full.png' : url + '/uploads/default/heart-blank.png'} alt="" />
														</div>
													</div>
												</div>

												<div className="product_text">
													<h4 className="title_brand title_p p_bottom">{item.title}</h4>
													{item.review !== 0 && (
														<div className="root_star" onClick={() => starClickHandler(item)}>
															<ReactStars {...starController} value={item.review / item.total} />
															<span className="star_span">({item.total})</span>
														</div>
													)}

													<div className="business_bottom_main">

														{(item.latitude && item.longitude && item.address) && <>
															<div className="business_address">
																<div className="location">
																	<div className="img">
																		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
																			<g clip-path="url(#clip0_308_62)">
																				<path d="M7 0C4.1055 0 1.75 2.37183 1.75 5.28792C1.75 9.43133 6.5065 13.7095 6.70892 13.8892C6.79233 13.9632 6.89617 14 7 14C7.10383 14 7.20767 13.9633 7.29108 13.8898C7.4935 13.7095 12.25 9.43133 12.25 5.28792C12.25 2.37183 9.8945 0 7 0ZM7 8.16667C5.39175 8.16667 4.08333 6.85825 4.08333 5.25C4.08333 3.64175 5.39175 2.33333 7 2.33333C8.60825 2.33333 9.91667 3.64175 9.91667 5.25C9.91667 6.85825 8.60825 8.16667 7 8.16667Z" fill="#258F67" />
																			</g>
																			<defs>
																				<clipPath id="clip0_308_62">
																					<rect width="14" height="14" fill="white" />
																				</clipPath>
																			</defs>
																		</svg>
																	</div>
																	<div className="address">
																		<span>{item.address}</span>
																	</div>

																</div>
															</div>
															<div className="business_distance">
																<div className="img">
																	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
																		<g clip-path="url(#clip0_308_66)">
																			<path d="M4.55928 0.0217285C2.82501 0.0217285 1.4191 1.42764 1.4191 3.16192V11.1868H0.721278C0.439038 11.1868 0.184585 11.3568 0.0765767 11.6176C-0.0314318 11.8784 0.0282668 12.1785 0.227843 12.3781L1.62348 13.7737C1.896 14.0462 2.33783 14.0462 2.61035 13.7737L4.00599 12.3781C4.20556 12.1785 4.26527 11.8784 4.15726 11.6176C4.04925 11.3568 3.7948 11.1868 3.51256 11.1868H2.81474V3.16192C2.81474 2.19843 3.5958 1.41737 4.55928 1.41737C5.52277 1.41737 6.30381 2.19843 6.30381 3.16192V10.8379C6.30381 12.5722 7.70971 13.9781 9.444 13.9781C11.1783 13.9781 12.5842 12.5722 12.5842 10.8379V4.08951C13.3973 3.80212 13.9798 3.02669 13.9798 2.11519C13.9798 0.959005 13.0426 0.0217285 11.8864 0.0217285C10.7301 0.0217285 9.79291 0.959005 9.79291 2.11519C9.79291 3.0267 10.3754 3.80215 11.1885 4.08953V10.8379C11.1885 11.8014 10.4075 12.5825 9.444 12.5825C8.48052 12.5825 7.69945 11.8014 7.69945 10.8379V3.16192C7.69945 1.42764 6.29355 0.0217285 4.55928 0.0217285Z" fill="#258F67" />
																		</g>
																		<defs>
																			<clipPath id="clip0_308_66">
																				<rect width="14" height="14" fill="white" />
																			</clipPath>
																		</defs>
																	</svg>
																</div>
																<div className="text">
																	<span>{distance} miles</span>
																</div>

															</div>
														</>
														}

														{(item.category && JSON.parse(item.category).length > 0) &&
															<div className="business_category">
																{JSON.parse(item.category).map(cat => {
																	return <a className="badge rounded-pill badge-lg">{cat.value}</a>
																})}
															</div>
														}


													</div>


												</div>
												{/* business bottom end  */}
											</div>

										</div>
									</SplideSlide>
								)
							})}
						</CarouselAll>
					</div>
				)}

				{/* It is for non slider */}
				{!slider && (
					<>
						<div className="products all_products row">
							{productItem.map((item, i) => {

								if (item.latitude && item.longitude) {
									var from = turf.point([cordinates.lat, cordinates.lng])
									var to = turf.point([item.latitude, item.longitude])
									var distance = Math.round(turf.distance(from, to, options))
								}
								let profile = item.profile ? JSON.parse(item.profile) : businessProfile

								return (
									<div className={col + " col-md-6"} id={"business-" + item.id}>
										<div className="brand_wrapper" onClick={(e) => sendFavorite(e, i, item.id, item.title, item.url, item.businessId)} >
											<div className="main_img">
												<div className="brand_items">
													<div className="img_wrapper">
														<img src={profile.url} alt="" />
													</div>
													<div className="product_icon">
														<img className={"icon"} src={item["business_wishes.businessId"] ? url + '/uploads/default/heart-full.png' : url + '/uploads/default/heart-blank.png'} alt="" />
													</div>
												</div>
											</div>
											<div className="product_text">
												<h4 className="title_brand title_p p_bottom">{item.title}</h4>
												{item.review !== 0 && (
													<div className="root_star" onClick={() => starClickHandler(item)}>
														<ReactStars {...starController} value={item.review / item.total} />
														<span className="star_span">({item.total})</span>
													</div>
												)}
												<div className="business_bottom_main">

													{(item.latitude && item.longitude && item.address) && <>
														<div className="business_address">
															<div className="location">
																<div className="img">
																	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
																		<g clip-path="url(#clip0_308_62)">
																			<path d="M7 0C4.1055 0 1.75 2.37183 1.75 5.28792C1.75 9.43133 6.5065 13.7095 6.70892 13.8892C6.79233 13.9632 6.89617 14 7 14C7.10383 14 7.20767 13.9633 7.29108 13.8898C7.4935 13.7095 12.25 9.43133 12.25 5.28792C12.25 2.37183 9.8945 0 7 0ZM7 8.16667C5.39175 8.16667 4.08333 6.85825 4.08333 5.25C4.08333 3.64175 5.39175 2.33333 7 2.33333C8.60825 2.33333 9.91667 3.64175 9.91667 5.25C9.91667 6.85825 8.60825 8.16667 7 8.16667Z" fill="#258F67" />
																		</g>
																		<defs>
																			<clipPath id="clip0_308_62">
																				<rect width="14" height="14" fill="white" />
																			</clipPath>
																		</defs>
																	</svg>
																</div>
																<div className="address">
																	<span>{item.address}</span>
																</div>

															</div>
														</div>

														<div className="business_distance">
															<div className="img">
																<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
																	<g clip-path="url(#clip0_308_66)">
																		<path d="M4.55928 0.0217285C2.82501 0.0217285 1.4191 1.42764 1.4191 3.16192V11.1868H0.721278C0.439038 11.1868 0.184585 11.3568 0.0765767 11.6176C-0.0314318 11.8784 0.0282668 12.1785 0.227843 12.3781L1.62348 13.7737C1.896 14.0462 2.33783 14.0462 2.61035 13.7737L4.00599 12.3781C4.20556 12.1785 4.26527 11.8784 4.15726 11.6176C4.04925 11.3568 3.7948 11.1868 3.51256 11.1868H2.81474V3.16192C2.81474 2.19843 3.5958 1.41737 4.55928 1.41737C5.52277 1.41737 6.30381 2.19843 6.30381 3.16192V10.8379C6.30381 12.5722 7.70971 13.9781 9.444 13.9781C11.1783 13.9781 12.5842 12.5722 12.5842 10.8379V4.08951C13.3973 3.80212 13.9798 3.02669 13.9798 2.11519C13.9798 0.959005 13.0426 0.0217285 11.8864 0.0217285C10.7301 0.0217285 9.79291 0.959005 9.79291 2.11519C9.79291 3.0267 10.3754 3.80215 11.1885 4.08953V10.8379C11.1885 11.8014 10.4075 12.5825 9.444 12.5825C8.48052 12.5825 7.69945 11.8014 7.69945 10.8379V3.16192C7.69945 1.42764 6.29355 0.0217285 4.55928 0.0217285Z" fill="#258F67" />
																	</g>
																	<defs>
																		<clipPath id="clip0_308_66">
																			<rect width="14" height="14" fill="white" />
																		</clipPath>
																	</defs>
																</svg>
															</div>

															<div className="text">
																<span>{distance} miles</span>
															</div>

														</div>
													</>
													}

													{(item.category && JSON.parse(item.category).length > 0) &&
														<div className="business_category">
															{JSON.parse(item.category).map(cat => {
																return <a className="badge rounded-pill badge-lg">{cat.value}</a>
															})}
														</div>
													}

												</div>


											</div>
											{/* business bottom end  */}
										</div>
									</div>
								)
							})}
						</div>
					</>
				)}
			</div>
		</>
	)
}

export default Business
