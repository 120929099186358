import React, { useState, useContext, useEffect } from "react"
import { url } from "../../helpers/url"
import { math } from "../../helpers/functions"
import axios from "axios"
import { Context } from "../../helpers/context"
import ReactStars from "react-rating-stars-component"
// import { SplideSlide } from "@splidejs/react-splide"
// import CarouselAll from "../../components/carousel/all-carousel"
import "./product.css"
import { Toastify, Toast } from "../toastify/toastify"
import { dynamicTitle, substring } from "../../helpers/functions"
import HtmlParser from "react-html-parser"
import { useHistory, Link } from "react-router-dom"

const strain = "/strain/"
const merchandise = "/merchandise/"


const Products = ({ slider, data, page, title, cordinates, productType, link, css, zindex, cartItem, description, homepage, route }) => {
	const [user, setUser] = useContext(Context)
	const [productItem, setProductItem] = useState([])

	const history = useHistory();
	useEffect(() => {
		setProductItem(data)
	}, [data])

	const sendFavorite = (e, arrId, product, title, path, type, businessId) => {
		const finalURL = type === 'business' ? strain + path : merchandise + path
		if (!(e.target.className === 'icon' || e.target.className === 'cart')) {
			if (type === 'business') {
				let discover = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
				discover.unshift(product)
				const newArray = [...new Set(discover)]
				if (newArray.length > 8) {
					newArray.pop()
				}
				localStorage.setItem('products', JSON.stringify(newArray))
			}
			history.push(finalURL)
		}
		if (e.target.className === 'icon') {
			if (user.type) {
				productItem[arrId]["product_wishes.productId"] = !productItem[arrId]["product_wishes.productId"]
				setProductItem([...productItem])
				let notice = { url: finalURL, notice: title, notice_type: 'wish', type: 'customer' }
				if (!productItem[arrId]["product_wishes.productId"]) {
					notice['notice_type'] = 'unwish'
					Toast("You have unwished")
				} else {
					Toast("You have wished")
				}

				axios.post(`${url}/wishlist/${productType === 'business' ? 'strain' : 'merchandise'}/api`, {
					productId: product,
					businessId: businessId,
					mainUserId: productItem[arrId].userId,
					title: substring(productItem[arrId].title, 20),
					url: productItem[arrId].url
				})
					.then(({ data }) => {
						if (data.notification) {
							user.socket.emit("addNotification", data.notification)
						}
					})

			} else {
				setUser({ ...user, loginPopup: true, pageYOffset: window.pageYOffset })
			}
		}
	}



	const maxString = (str) => {
		if (str) {
			const ar = str.split(" ")
			if (ar.length > 3) {
				const cleanArray = ar.filter(function (el) {
					return el
				})
				return cleanArray.slice(0, 3).join(" ") + "..."
			} else {
				return str
			}
		}
	}

	const dynamicContent = (content) => {
		if (content) {
			let newDyP = content.replace(/(<([^>]+)>)xA0/ig, '')
			if (newDyP.length > 100) {
				return "<p>" + substring(newDyP, 100) + "</p>"
			} else {
				return "<p>" + newDyP + "</p>"
			}
		}
	}

	const starController = {
		edit: false,
		half: false,
		count: 5,
		size: 22,
		isHalf: true,
		color: '#C4C4C4',
		activeColor: '#EFC368'
	}

	const addToCart = (product, cartMode, curVariaiton, variationId) => {
		let updateCart
		if (localStorage.getItem('cart')) {
			updateCart = JSON.parse(localStorage.getItem('cart'))
		} else {
			updateCart = {}
		}

		if (cartMode === "add") {
			cartItem(product)

			if (!curVariaiton) {
				let total = updateCart[product.id] ? updateCart[product.id].total : 0
				let min = product.min
				let stock = product.stock
				if (stock >= (total + min)) {
					updateCart[product.id] = {
						id: product.id,
						title: product.title,
						price: product.price,
						discount: product.discount,
						total: total + product.min,
						image: (product.image && JSON.parse(product.image).length > 0) ? JSON.parse(product.image)[0].url : process.env.REACT_APP_PORUDCT_IMAGE,
						stock: product.stock,
						min: product.min,
						url: merchandise + product.url
					}
					Toast("Product is added to cart")
				}
				else {
					Toast("You can not add more item because it is out of stock")
				}
			}
			if (curVariaiton) {
				let total = updateCart[product.id + "_" + variationId] ? parseInt(updateCart[product.id + "_" + variationId].total) : 0
				let min = parseInt(curVariaiton.min)
				let stock = parseInt(curVariaiton.stock)
				if (stock >= (total + min)) {
					updateCart[product.id + "_" + variationId] = {
						id: product.id,
						title: dynamicTitle(product.title, curVariaiton),
						variationId: variationId,
						price: curVariaiton.price,
						discount: curVariaiton.discount,
						total: total ? total + 1 : min,
						image: curVariaiton.image.url,
						color: curVariaiton.Color,
						size: curVariaiton.Size,
						weight: curVariaiton.Weight,
						stock: stock,
						min: min,
						url: merchandise + product.url
					}
					Toast("Product is added to cart")
				} else {
					Toast("You can not add more item because it is out of stock")
				}
			}
		}
		setUser({ ...user, ...{ cart: updateCart } })
		if (typeof window !== "undefined") {
			localStorage.setItem("cart", JSON.stringify(updateCart))
		}
	}


	const isItlove = (e, url, type, id) => {
		if (e.target.tagName !== "I") {
			if (type === "business") {
				const getPro = localStorage.getItem("products")
				if (getPro) {
					let parse = JSON.parse(getPro)
					if (parse.length > 19) {
						parse.splice(19, 1)
					}
					const findItem = [id, ...parse]
					const updateItem = [...new Set(findItem)]
					localStorage.setItem("products", JSON.stringify(updateItem))
				} else {
					localStorage.setItem("products", JSON.stringify([id]))
				}
			}

		}
	}

	const starClickHandler = (item) => {
		// history.push("/strain/" + item.url + '#starbox')
		window.location.href = "/strain/" + item.url + '#starbox'
	}

	return (
		<>

			<div>
				{/* It is for  slider */}

				<Toastify />

				{/* {slider && (
					<div className={"products " + productType}>
						<CarouselAll page={4} title={title} link={link}>
							{productItem.map((item, i) => {
								let image
								let price
								let discount
								let curVariaiton
								let variationId
								if (item.variation) {
									const parseVairation = JSON.parse(item.variation)
									const parseVairationKeys = Object.keys(parseVairation)
									variationId = parseVairationKeys[0]
									curVariaiton = parseVairation[variationId]
									price = curVariaiton.price
									discount = curVariaiton.discount
									image = curVariaiton.image ? curVariaiton.image.url : process.env.REACT_APP_PORUDCT_IMAGE
								} else {
									image = JSON.parse(item.image)[0].url
									price = item.price
									discount = item.discount
								}

								return (
									<SplideSlide>

										<div className="brand_wrapper" onClick={(e, id) => sendFavorite(i, item.id, item.title, item.url, item.underUserId)}>
											<div className="main_img">
												<p></p>
												<div className="brand_items">
													<img src={image} alt="" />
													<div className="product_icon">
														<img className={"icon"} src={item["product_wishes.productId"] ? url + '/uploads/default/heart-full.png' : url + '/uploads/default/heart-blank.png'} alt="" />
													</div>
												</div>
											</div>

											<div className="product_text">
												<h4 className="title_brand title_p p_bottom">{maxString(item.title)}</h4>
												{item.review !== 0 && (
													<div className="root_star" onClick={() => starClickHandler(item)}>
														<ReactStars {...starController} value={item.review / item.total} />
														<span className="star_span">({item.total})</span>
													</div>
												)}

												<div className="product_bottom">
													{productType === 'merchandise' &&
														<div className="product_bottom_left">
															{(!discount || discount === 0) && <h4 className="title_brand title_p p_bottom price">${math(price).toFixed(2)}</h4>
															}
															{discount > 0 && <h4 className="title_brand title_p p_bottom price">${math(discount).toFixed(2)} <del className="discount">${math(price).toFixed(2)}</del></h4>
															}
														</div>
													}
													<div className="product_bottom_right">
														<div className="first_pro_btn">
															<a href={item.url} className="product_btn" rel="noopener noreferrer">
																View Product
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</SplideSlide>
								)
							})}
						</CarouselAll>
					</div>
				)} */}

				{/* It is for non slider */}
				{!slider && (
					<>
						{title &&
							<div className={"all_product_heading row " + css}>
								<div className="col heading_left">
									<h3>{title}</h3>
									{description && <p className="description">{description}</p>}
								</div>
							</div>
						}
						<div className={"products all_products row " + productType}>
							{productItem.map((item, i) => {
								let image
								let price
								let discount
								let curVariaiton
								let variationId
								let newItem = 0
								if (item.variation) {
									const parseVairation = JSON.parse(item.variation)
									const parseVairationKeys = Object.keys(parseVairation)
									variationId = parseVairationKeys[0]
									curVariaiton = parseVairation[variationId]
									price = curVariaiton.price
									discount = curVariaiton.discount
									image = curVariaiton.image ? curVariaiton.image.url : process.env.REACT_APP_PORUDCT_IMAGE
								} else {
									image = JSON.parse(item.image).length > 0 ? JSON.parse(item.image)[0].url : process.env.REACT_APP_PORUDCT_IMAGE
									price = item.price
									discount = item.discount
								}
								return (
									<div className={"col-lg-3 col-md-4 col-sm-6 products_item " + zindex} id={"product-" + item.id}>
										<div className="brand_wrapper" onClick={(e) => sendFavorite(e, i, item.id, item.title, item.url, item.type, item.businessId)} >
											<div className="main_img">
												<div className="brand_items">
													<img src={image} alt="" />
													<div className="product_icon">
														<img className={"icon"} src={item["product_wishes.productId"] ? url + '/uploads/default/heart-full.png' : url + '/uploads/default/heart-blank.png'} alt="" />
													</div>
													{productType === 'merchandise' &&
														<div className="product_icon_2" onClick={() => addToCart(item, "add", curVariaiton, variationId)} >
															<img className="cart" src={url + '/uploads/default/' + 'add_to_cart.png'} alt="" />
														</div>
													}
													{(discount > 0 || newItem > 0) &&
														<div className="product_icon_3">
															<p>{(discount > 0) ? 'Sale' : 'New'}</p>
														</div>
													}
												</div>
											</div>

											<div className="product_text">
												<h4 className="title_brand title_p p_bottom">{maxString(item.title)}</h4>
												{item.review !== 0 && (
													<div className="root_star" onClick={() => starClickHandler(item)}>
														<ReactStars {...starController} value={item.review / item.total} />
														<span className="star_span">({item.total})</span>
													</div>
												)}

												{(productType === 'business') && <p className="thc">THC {item.thc + item.thc_type}</p>}

												{(productType === 'business') && <p className="cbd">CBD {item.cbd + item.thc_type} </p>}


												{(productType === 'merchandise') &&
													<div className="merchandise_description">
														{HtmlParser(dynamicContent(item.description))}
													</div>
												}

												{productType === 'merchandise' && <>
													{(!discount || discount === 0) && <h4 className="title_brand title_p p_bottom price">${item.minPrice === item.maxPrice ? math(price).toFixed(2) : math(item.minPrice).toFixed(2) + '-' + math(item.maxPrice).toFixed(2)}</h4>
													}
													{discount > 0 && <h4 className="title_brand title_p p_bottom price">${math(discount).toFixed(2)} <del className="discount">${math(price).toFixed(2)}</del></h4>
													}
												</>
												}
											</div>

											{/* Product bottom start */}
											{/* <div className="product_bottom">
												<div className="product_bottom_right">
													<div className="first_pro_btn">
														<a href={item.url} className="" rel="noopener noreferrer">
															{productType === 'merchandise' ? 'View Product' : '	Read Review'}
														</a>
													</div>
												</div>
											</div> */}
											{/* Product bottom start */}
										</div>
									</div>
								)
							})}
							{homepage &&
								<div className={"col-lg-6 col-md-12 products_next_box " + zindex} style={{
									backgroundImage: `url('${url}/uploads/default/product_next.png')`
								}}>
									<div className="product_next_items">
										<p className="verified">Trusted. Vetted. Verified</p>
										<p className="description">Our collection of cannabis strains handpicked from the best growers are organic, free from chemical additives, and lab tested to guarantee purity and safety.</p>
										<Link to="/strain" className="all_pro_btn">All Products</Link>
									</div>
								</div>
							}
						</div>
					</>
				)}
			</div >
		</>
	)
}

export default Products
