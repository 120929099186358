import React, { useContext, useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import "./public/css/font-awesome.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./public/css/normalize.css"
import "./public/css/main.css"
import Header from "./components/header/header"
import Footer from "./components/footer/footer"
import Home from "./containers/home/home"
import SingleBusiness from "./containers/single-business"
import SingleProduct from "./containers/single-product"
import Login from "./containers/login"
// import WriterLogout from "./containers/writer-logout"
import RegistrationConfirm from "./components/login/confirmation"
import Registration from "./containers/registration"
import Wrtier from "./containers/writer"
import WrtierConfirmation from "./containers/writer-confirmation"
import ReviewProduct from "./containers/product/product"
import Merchandise from "./containers/merchandise/merchandise"
import Cart from "./containers/cart/cart"
// import Checkout from "./containers/checkout"
import Business from "./containers/business/business"
import Success from "./containers/success"
import Cancel from "./containers/cancel"
import Orders from "./containers/orders/orders"
import Forgot from "./containers/forgot"
import Reset from "./containers/reset"
import Search from "./containers/search/search"
import NotFound from "./containers/404"
import Contact from "./containers/contact/contact"
import SingleProCat from "./containers/product-category"
import SingleMerCat from "./containers/merchandise-category"
import NewsCat from "./containers/news-category"
import NewMap from "./components/new-map/map"
import Profile from "./components/profile/profile"
import News from "./containers/news"
import SingleNews from "./containers/single-news/single-news"
import Favourite from "./containers/favourite"
import SearchBusiness from "./containers/search-business/search-business"
import Discover from "./containers/discover"
import Invoice from "./containers/invoice/invoice"
import Terms from "./containers/terms/terms"
import About from "./components/about/about"

// Social
import FriendRequest from "./containers/social/friend_request"
import FindFriend from "./containers/social/find_friend"
import Chat from "./containers/social/chat"
import DynamicChat from "./containers/social/dynamic-chat"
import MyPost from "./containers/social/my-post"
import Feed from "./containers/social/feed"
import SocialSingleProfile from "./containers/social/single-profile"
import SocialSinglePost from "./containers/social/single-post"
import Friends from "./containers/social/friend"
import Block from "./containers/social/block"

// Influencer 
import InfluencerFeed from "./containers/influencer/influencer-feed"
import Myinfluencer from "./containers/influencer/my-influencer"
import InfluencerSingleProfile from "./containers/influencer/single-profile"
import InfluencerSinglePost from "./containers/influencer/single-post"
import InfluencerRegistraion from "./containers/influnencer-registration"



import Popup from "./components/popup/popup"
import Install from "./containers/install"

import Deals from "./containers/deals"
import WriteReview from "./containers/write-review/write-review"
import Subscription from "./components/subscription/subscription"
import SubscriptionSuccess from "./components/subscription/success"
import SubscriptionCancel from "./components/subscription/cancel"
import Development from './components/development/development'
import LoginAlert from "./components/loginmessage/loginmessage"
import ScrollTop from "./helpers/scroll-top"

import axios from "axios"
import { Context } from "./helpers/context"
require('dotenv').config()

if (typeof window !== "undefined") {
	const token = localStorage.getItem("letsmango.com")
	axios.defaults.headers.common["Authorization"] = token

}

function App() {
	const [age, setAge] = useState("")
	const [load, setLoad] = useState(false)
	const [development, setDevelopment] = useState(false)
	const [user, setUser] = useContext(Context)

	useEffect(() => {
		if (localStorage.getItem("agemango")) {
			setAge(localStorage.getItem("agemango"))
		}
		if (localStorage.getItem("development")) {
			setDevelopment(true)
		}
		setLoad(true)
	}, [])

	return (
		<>
			{(!development && load) && <Development />}
			{(development && load) && (
				<>
					<div className="main_content">
						{!age && <Popup />}
						{user.loginPopup && <LoginAlert position={user.pageYOffset} />}

						<Header />
						<ScrollTop />
						<Switch>
							<Route exact path="/" component={Home} />
							<Route exact path="/deals" component={Deals} />
							<Route exact path="/search" component={Search} />
							<Route exact path="/review" component={WriteReview} />


							<Route exact path="/dispensary" component={Business} />
							<Route exact path="/strain" component={ReviewProduct} />
							<Route exact path="/merchandise" component={Merchandise} />

							<Route exact path="/cart" component={Cart} />
							{/* <Route exact path="/checkout" component={Checkout} /> */}
							<Route exact path="/checkout/success" component={Success} />
							<Route exact path="/checkout/cancel" component={Cancel} />
							<Route exact path="/orders" component={Orders} />

							{/* Below these 3 url start for category  */}
							<Route exact path="/category/merchandise/:id" component={SingleMerCat} />
							<Route exact path="/category/:id" component={SingleProCat} />
							<Route exact path="/:id/news" component={NewsCat} />
							{/* Above these 3 url for category  */}


							<Route exact path="/merchandise/:id" component={SingleProduct} />
							<Route exact path="/strain/:id" component={SingleProduct} />
							<Route exact path="/dispensary/:id" component={SingleBusiness} />

							<Route exact path="/register" component={() => <Registration userType="customer" title="Sign Up" button="Create Account" />} />


							<Route exact path="/writer/request" component={WrtierConfirmation} />

							<Route exact path="/writer" component={() => <Wrtier userType="business" title="News Writer" button="News Writing Request" api={"/news/writer/api"} />} />


							<Route exact path="/influencer/signup" component={() => <Wrtier userType="influencer" title="Influencer Signup" button="Become Influencer" api={"/influencer/signup"} />} />


							<Route exact path="/business-register" component={() => <Registration userType="business" title="Create Business Account" button="Create Account" />} />


							{/* <Route exact path="/login/writer" component={WriterLogout} /> */}
							<Route exact path="/login" component={Login} />
							<Route exact path="/login/confirmation" component={RegistrationConfirm} />
							<Route exact path="/contact" component={Contact} />
							<Route exact path="/about" component={About} />
							<Route exact path="/forgot" component={Forgot} />
							<Route exact path="/reset" component={Reset} />

							<Route exact path="/map" component={NewMap} />

							<Route exact path="/profile/edit" component={Profile} />
							<Route exact path="/news" component={News} />
							<Route exact path="/news/:id" component={SingleNews} />
							<Route exact path="/favourite" component={Favourite} />
							<Route exact path="/search-business" component={SearchBusiness} />
							<Route exact path="/discover" component={Discover} />
							<Route exact path="/invoices/:id" component={Invoice} />
							<Route exact path="/terms" component={Terms} />
							<Route exact path="/subscription" component={Subscription} />
							<Route exact path="/subscription/success" component={SubscriptionSuccess} />
							<Route exact path="/subscription/cancel" component={SubscriptionCancel} />

							{/* Influencer start */}



							<Route path="/influencer" exact component={InfluencerFeed} />
							<Route path="/influencer/my-post" exact component={Myinfluencer} />
							<Route path="/influencer/post/:id/:userId" exact component={InfluencerSinglePost} />
							<Route path="/influencer/:id" exact component={InfluencerSingleProfile} />
							{/* Influencer end */}


							{/* Social start */}
							<Route path="/social/friend-request" exact component={FriendRequest} />
							<Route path="/social/find-friend" exact component={FindFriend} />
							<Route path="/social/post" exact component={MyPost} />
							<Route path="/social/post/:id/:userId" exact component={SocialSinglePost} />
							<Route path="/social/friends" exact component={Friends} />
							<Route path="/social/block" exact component={Block} />
							<Route path="/social/feed" exact component={Feed} />
							<Route path="/social/messages" exact component={Chat} />
							<Route path="/social/messages/:id" exact component={DynamicChat} />
							<Route path="/social/:id" exact component={SocialSingleProfile} />
							{/* Social end */}

							<Route exact path="/install" component={Install} />
							<Route component={NotFound} />
						</Switch>
						<Footer />
					</div>
				</>
			)}

		</>
	)
}

export default App
