import "./header.css"
import React, { useState, useEffect, useContext, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { url } from "../../helpers/url"
import { Context } from "../../helpers/context"
import axios from "axios"
import Autocomplete from "react-google-autocomplete"
import { screen } from "../../helpers/functions"
import InfiniteScroll from "react-infinite-scroller"
import ReactHtmlParser from 'react-html-parser';
import ReactStars from "react-rating-stars-component"
import { math } from "../../helpers/functions"
import { socket } from "../../helpers/socket"
import { feedTime } from "../../helpers/time"


const notifyMsg = (item) => {

	switch (item.type) {

		case 'comment':
			return (
				<Link to={"/social/post/" + item.postId + "/" + item.userId}>
					<p>{"Your post " + item.notice +
						" got " + item.total + " comment"}
					</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)

		case 'share':
			return (
				<Link to={"/social/post/" + item.postId + "/" + item.userId}>
					<p>{"Your post " + item.notice +
						" got " + item.total + " share"}
					</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)


		case 'comment_like':
			return (<Link to={"/social/post/" + item.postId + "/" + item.userId}>
				<p>{"Your comment " + item.notice +
					" got " + item.total + " like"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'post_like':
			return (<Link to={"/social/post/" + item.postId + "/" + item.userId}>
				<p>{"Your post " + item.notice +
					" got " + item.total + " like"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'in_comment':
			return (
				<Link to={"/influencer/post/" + item.postId + "/" + item.userId}>
					<p>{"Your post " + item.notice +
						" got " + item.total + " comment"}
					</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)

		case 'in_share':
			return (
				<Link to={"/influencer/post/" + item.postId + "/" + item.userId}>
					<p>{"Your post " + item.notice +
						" got " + item.total + " share"}
					</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)


		case 'in_comment_like':
			return (<Link to={"/influencer/post/" + item.postId + "/" + item.userId}>
				<p>{"Your comment " + item.notice +
					" got " + item.total + " like"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'in_post_like':
			return (<Link to={"/influencer/post/" + item.postId + "/" + item.userId}>
				<p>{"Your post " + item.notice +
					" got " + item.total + " like"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'friend_request':
			return (<Link to={"/social/" + item.senderId}>
				<p>{"You got a new friend request"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'cus_friend_request':
			return (<Link to={"/social/" + item.senderId}>
				<p>{"You got a new follower"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>
			)
		case 'in_friend_request':
			return (<Link to={"/influencer/" + item.senderId}>
				<p>{"You got a new follower"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>
			)

		case 'accept_request':
			return (
				<Link to={"/social/" + item.senderId}>
					<p>{"Your friend request has been accepted"}</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)

		case 'accept_request':
			return (
				<Link to={"/social/" + item.senderId}>
					<p>{"Your friend request has been accepted"}</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)
		case 'in_accept_request':
			return (
				<Link to={"/influencer/" + item.senderId}>
					<p>{"You got back a follower"}</p>
					<p>{feedTime(item.updatedAt, 'ago')}</p>
				</Link>
			)

		// Below are products and businesses related things 

		case 'strain':
			return (<Link to={"/strain/" + item.url}>
				<p>{"Your product " + item.notice +
					" got " + item.total + " love"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'merchandise':
			return (<Link to={"/merchandise/" + item.url}>
				<p>{"Your product " + item.notice +
					" got " + item.total + " love"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'dispensary':
			return (<Link to={"/dispensary/" + item.url}>
				<p>{"Your dispensary " + item.notice +
					" got " + item.total + " love"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'star_dispensary':
			return (<Link to={"/dispensary/" + item.url}>
				<p>{"Your dispensary " + item.notice +
					" got " + item.total + " review"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		case 'star_strain':
			return (<Link to={"/strain/" + item.url}>
				<p>{"Your dispensary " + item.notice +
					" got " + item.total + " review"}
				</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</Link>)

		default: return (
			<div className="admin_notice">
				{ReactHtmlParser(item.notice)}
				<p className="by_admin">by Admin</p>
				<p>{feedTime(item.updatedAt, 'ago')}</p>
			</div>
		)
	}

}


const SearchBar = ({ corProp, placeProp }) => {
	const history = useHistory()
	const [cordinates, setCordinates] = useState()
	const [currentPlace, setCurrentPlace] = useState()
	const [cor, setCor] = useState([])

	useEffect(() => {
		if (corProp.lat && corProp.lng) {
			setCordinates(corProp)
			setCurrentPlace(placeProp)
			setCor([corProp.lat, corProp.lng])
		}

	}, [corProp])

	const [filter, setFilter] = useState('Dispensary')
	const [rows, setRows] = useState([])
	const [count, setCount] = useState([])
	const [timer, setTimer] = useState(null);
	const [found, setFound] = useState(true)
	const resultRef = useRef(null)

	useEffect(() => {
		// below it for search result if click outside 
		const searchResultClick = (e) => {
			if (resultRef && resultRef.current && !resultRef.current.contains(e.target)) {
				setRows([])
				setFound(true)
			}
		};
		const selectClick = (e) => {
			if (selectRef && selectRef.current && !selectRef.current.contains(e.target)) {
				setSelectDisplay(false)
			}
		};
		window.addEventListener("click", searchResultClick);
		window.addEventListener("click", selectClick);
		return () => window.removeEventListener("click", searchResultClick, selectClick);
	}, [])

	const searchHandler = (e) => {
		const search = e.target.value
		setSearchText(search)

		if (timer) {
			clearTimeout(timer);
			setTimer(null);
		}
		setTimer(
			setTimeout(async () => {
				let apiURL
				if (filter === 'Dispensary') {
					apiURL = url + '/search/business/api'
				}
				else if (filter === 'Strain') {
					apiURL = url + '/search/product/api'
				}
				else if (filter === 'Merchandise') {
					apiURL = url + '/search/merchandise/api'
				}
				if (search.trim()) {
					axios.post(apiURL, { search: search.trim(), page: 0, limit: 20, lat: cordinates.lat, lng: cordinates.lng }).then(({ data }) => {
						if (data.success && data.rows && data.rows.length > 0) {
							setRows(data.rows)
							setCount(data.count)
							setFound(true)
						} else {
							setFound(false)
						}
					})
				}
			}, 1000)
		);
	};


	const [searchText, setSearchText] = useState("")

	const addressChanger = (e) => {
		const ad = e.target.value
		setCurrentPlace(ad)
	}
	const placeController = (place) => {
		setCurrentPlace(place.formatted_address)
		setCor([place.geometry.location.lat(), place.geometry.location.lng()])
	}


	const searchRef = useRef()

	const [selectWidth, setSelectWidth] = useState(98)
	const selectRef = useRef()

	const selectHandler = (value) => {
		setSearchText('')
		if (value === 'Strain') {
			setSelectWidth(64)
		}
		else if (value === 'Dispensary') {
			setSelectWidth(98)
		}
		else if (value === 'Merchandise') {
			setSelectWidth(105)
		}
		setFilter(value)
		setSelectDisplay(false)
		if (searchRef) {
			searchRef.current.focus()
		}
	}

	const [selectDisplay, setSelectDisplay] = useState(false)
	const selectedItemHandler = () => {
		setSelectDisplay(!selectDisplay)
	}

	const starController = {
		edit: false,
		half: false,
		count: 5,
		size: 22,
		isHalf: true,
		activeColor: '#EFC368'
	}

	const search = (e) => {
		e.preventDefault()
		history.push(`/search?search=${searchText}&lat=${cor[0]}&lng=${cor[1]}&type=${filter}&page=0`)
	}

	return (
		<form onSubmit={search}>
			<div className="search_bar_items">
				<div className="address_input">
					<Autocomplete
						className=""
						options={{
							// types: ['geocode',],
							// (regions)
							// 'address'
							types: ["(regions)"],
							componentRestrictions: { country: "us" },
						}}
						apiKey={process.env.REACT_APP_MAP_KEY}
						onPlaceSelected={placeController}
						onChange={addressChanger}
						value={currentPlace}
						placeholder="Address"
					/>
				</div>
				<div className="custom_select_item">
					<div ref={selectRef} className="selected_item" onClick={selectedItemHandler} style={{ width: selectWidth }}>
						{filter}<i className="fa fa-angle-down"></i>
					</div>
					{selectDisplay &&
						<ul>
							<li onClick={() => selectHandler('Dispensary')} className="">Dispensary</li>
							<li onClick={() => selectHandler('Strain')} className="">Strain</li>
							<li onClick={() => selectHandler('Merchandise')} className="">Merchandise</li>
						</ul>
					}

				</div>
				{/* <div className="select_item">
					<select name="" id="" onChange={selectHandler} style={{ width: selectWidth }}>
						<option value="Dispensary">Dispensary</option>
						<option value="Strain">Strain</option>
						<option value="Merchandise">Merchandise</option>
					</select>
				</div> */}
				<div className="search_input">
					<div className="input_left">
						<input ref={searchRef} required onChange={searchHandler} name="search" type="search" placeholder="Search product" className="home_search_input" value={searchText} autoComplete="off" />
					</div>
					<div className="input_right">
						<input type="image" src={url + '/uploads/default/icons8-search-20.png'} alt="Submit" />
					</div>
					{(rows.length > 0 || !found) &&
						<div className="search_result" ref={resultRef} onClick={() => setRows([])}>
							{
								rows.map(item => {
									let price
									let discount
									let curVariaiton
									let variationId
									let image
									let currentURL
									if (filter === 'Merchandise') {
										if (item.variation) {
											currentURL = '/merchandise/' + item.url
											const parseVairation = JSON.parse(item.variation)
											const parseVairationKeys = Object.keys(parseVairation)
											variationId = parseVairationKeys[0]
											curVariaiton = parseVairation[variationId]
											price = curVariaiton.price
											discount = parseInt(curVariaiton.discount)
											image = curVariaiton.image ? curVariaiton.image.url : process.env.REACT_APP_MERCHANDISE_IMAGE
										} else {
											currentURL = '/merchandise/' + item.url
											image = JSON.parse(item.image).length > 0 ? JSON.parse(item.image)[0].url : process.env.REACT_APP_MERCHANDISE_IMAGE
											price = item.price
											discount = parseInt(item.discount)
										}
									} else if (filter === 'Dispensary') {
										currentURL = '/dispensary/' + item.url
										image = item.profile ? JSON.parse(item.profile).url : process.env.REACT_APP_BUSINESS_PROFILE_IMAGE
									} else {
										currentURL = '/strain/' + item.url
										image = JSON.parse(item.image).length > 0 ? JSON.parse(item.image)[0].url : process.env.REACT_APP_PORUDCT_IMAGE
									}

									return (
										<Link to={currentURL} className="result_anchor">
											<div className="search_result_item">
												<div className="search_result_img">
													<img src={image} alt="" />
												</div>
												<div className="search_result_content">
													<h3>{item.title}</h3>

													{(filter === 'Merchandise') && (!discount || discount === 0) && <h4 className="title_brand title_p p_bottom price">${item.minPrice === item.maxPrice ? math(price).toFixed(2) : math(item.minPrice).toFixed(2) + '-' + math(item.maxPrice).toFixed(2)}</h4>
													}

													{(filter === 'Merchandise') && (discount > 0) && <h4 className="title_brand title_p p_bottom price">${math(discount).toFixed(2)} <del className="discount">${math(price).toFixed(2)}</del></h4>
													}

													{(item.total > 0 && filter != 'Merchandise') &&
														<div className="root_star">
															<ReactStars {...starController} value={item.review / item.total} />
															<span className="star_span">({item.total})</span>
														</div>
													}
												</div>
											</div>
										</Link>
									)
								})
							}

							{!found &&
								<div className="search_result_item">
									<div className="search_result_content">
										<h3>No results</h3>
									</div>
								</div>
							}
						</div>
					}
				</div>
			</div>
		</form>)
}


const Header = () => {
	const history = useHistory()
	const [type, setType] = useContext(Context)
	const [searchItem, setSearchItem] = useState({
		search: "",
		zip: "",
		cor: [],
		page: 0,
	})
	const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
	const [currentPlace, setCurrentPlace] = useState("")
	const [pageUrl, setPageUrl] = useState()



	useEffect(() => {
		if (typeof window !== "undefined") {
			function blockByPath() {
				const excludePath = ["/login", "/register", "/business-register", "/logout", "/login/confirmation", "/writer", "/influencer/signup", "/forgot", "/reset",]
				let blockPath = false
				excludePath.map((item) => {
					if (item === window.location.pathname) {
						blockPath = true
					}
				})
				return blockPath
			}

			if (!blockByPath()) {
				localStorage.setItem("page", window.location.href)
				setPageUrl(window.location.href)
			}

			axios.get(`${url}/notify/api?offset=0`).then((data) => {
				const result = data.data
				if (result.crnAddress && result.location) {
					setSearchItem({
						...searchItem,
						cor: [result.location.lat, result.location.lng],
					})
					setCurrentPlace(result.crnAddress.city)
				}
				setCordinates({
					...cordinates,
					lng: result.location.lng,
					lat: result.location.lat,
				})
				let length = 0
				result.notify.map(item => {
					if (item.seen === 0) {
						length += 1
					}
				})

				setType({
					...type,
					notify: result.notify,
					notifyLength: length,
					type: result.type,
					businessType: result.businessType,
					editor: result.editor,
					name: result.fname,
					fname: result.fname,
					lname: result.lname,
					image: result.image,
					userId: result.userId,
					cordinates: result.location,
					address: result.crnAddress,
					cart: JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : {},
					load: true,
				})
			})
		}
	}, [])



	// Live notification start
	const socketRef = useRef();


	useEffect(() => {
		socketRef.current = socket();
		socketRef.current.on("getNotification", (data) => {
			setNotification(data)
		})

	}, []);


	useEffect(() => {

		if (type.userId) {
			setType({ ...type, socketId: socketRef.current.id, socket: socketRef.current })
			socketRef.current.emit("addUser", type.userId, type.businessId)
		}

	}, [type.userId]);


	const [notification, setNotification] = useState(0)


	useEffect(() => {
		if (notification) {
			let copyNotify = [...type.notify]
			console.log(copyNotify)
			if (notification.status === 'update') {
				const filter = copyNotify.filter(item => item.id !== notification.notify.id)
				copyNotify = [notification.notify, ...filter]
			}

			if (notification.status === 'delete') {
				copyNotify = copyNotify.filter(item => item.id !== notification.notify.id)
			}

			if (notification.status === 'create') {
				copyNotify = [notification.notify, ...copyNotify]
			}


			let length = 0
			copyNotify.map(item => {
				if (item.seen === 0) {
					length += 1
				}
			})
			setType({ ...type, notify: copyNotify, notifyLength: length })
		}
	}, [notification])

	// useEffect(() => {
	// 	if (notification) {
	// 		axios.get(url + "/notify/live/api")
	// 			.then(({ data }) => {
	// 				let length = 0
	// 				data.notify.map(item => {
	// 					if (item.seen === 0) {
	// 						length += 1
	// 					}
	// 				})
	// 				setType({ ...type, notify: data.notify, notifyLength: length })
	// 			})
	// 	}
	// }, [notification])

	// Live notification end 




	const [mobile, setMobile] = useState(false)
	const isMenu = (e) => {
		if (e.target.className === 'svg') {
			setMobile(!mobile)
		}
	}

	const [submenu, setsubmenu] = useState(false)
	const submenuHandler = (e) => {
		setsubmenu(!submenu)
	}
	const [submenu2, setsubmenu2] = useState(false)
	const submenuHandler2 = (e) => {
		setsubmenu2(!submenu2)
	}


	useEffect(() => {
		window.addEventListener('click', (e) => {
			if (e.target.className !== 'svg' && e.target.className !== 'others_menu_tab') {
				setMobile(false)
			}
		})
	}, [])



	const setLogut = () => {
		localStorage.removeItem("letsmango.com")
		if (pageUrl.includes('#starbox')) {
			window.location.href = pageUrl.replace('#starbox', ' ')
		} else {
			window.location.href = pageUrl
		}
	}

	const [cart, setCart] = useContext(Context)
	const cartItem = Object.keys(cart.cart)

	const loginPopupHandler = () => {
		if (!type.type) {
			setType({ ...type, loginPopup: true, pageYOffset: window.pageYOffset })
		} else {
			history.push("/favourite")
		}
	}








	const [openNotification, setOpenNotification] = useState(false)

	const notificationHandler = () => {
		if (!type.type) {
			setType({ ...type, loginPopup: true, pageYOffset: window.pageYOffset })
		} else {
			setOpenNotification(!openNotification)
			if (type.notifyLength) {
				axios.post(url + '/notify/api')
					.then(({ data }) => {
						if (data.success) {
							setType({ ...type, notifyLength: 0 })
						}
					})
			}
		}
	}



	const notifyRef = useRef(null)
	const [hasMore, setHasMore] = useState(true)

	const findItem = () => {
		if (hasMore && type.notify.length === 10 && type.type) {
			setHasMore(false)
			axios.get(`${url}/notify/api?offset=` + type.notify.length).then(({ data }) => {
				if (data.success && data.notify.length > 0) {
					setType({ ...type, notify: [...type.notify, ...data.notify] })
					setHasMore(true)
				}
				if (data.notify.length === 0 && data.success) {
					setHasMore(false)
				}
			})
		}
	}

	useEffect(() => {
		const notificationClick = (e) => {
			if (notifyRef && notifyRef.current && !notifyRef.current.contains(e.target)) {
				setOpenNotification(false)
			}
		};
		window.addEventListener("click", notificationClick);
		return () => window.removeEventListener("click", notificationClick);
	}, [])


	return (
		<>
			<div className="header_main">
				<div className="container">
					{screen() > 991 ?
						<div className="main_header desktop_header">
							<div className="desktop_left">
								<div className="desktop_top">
									<div className="logo">
										<Link to="/">
											<img src={url + '/uploads/default/mango.png'} alt="" />
										</Link>
									</div>
									<div className="menu">
										<SearchBar corProp={cordinates} placeProp={currentPlace} />
										<div className="menu_items">

											<Link to="/strain">Strain</Link>
											<Link to="/dispensary">Dispensary</Link>
											<Link to="/merchandise">Merchandise</Link>
											<Link to="/news">News</Link>
											{/* <Link to="/deals">Deals</Link> */}
											<Link to="/review">Review</Link>

											{!type.type && <Link to="/influencer">Influencer </Link>}

											{type.type &&
												<div className="others_menu">
													<Link to="/">Influencer <i className="fa fa-angle-down"></i></Link>
													<div className="submenu">
														<div className="submneu_items">
															<Link to="/influencer/">Influencer Post</Link>
															<Link to="/influencer/my-post">My Post</Link>
														</div>

													</div>
												</div>
											}


											{type.type === 'customer' &&
												<div className="others_menu">
													<Link to="/">Social <i className="fa fa-angle-down"></i></Link>
													<div className="submenu">
														<div className="submneu_items">

															<Link to="/social/feed">Feed</Link>
															<Link to="/social/post">My Post</Link>
															<Link to="/social/messages">Chat</Link>
															<Link to="/social/friend-request">Friend Request</Link>
															<Link to="/social/find-friend">Find Friend</Link>
															<Link to="/social/friends">Friends</Link>
															<Link to="/social/block">Block List</Link>

														</div>

													</div>
												</div>
											}

											{/* Below is admin menu */}
											{(type.type === "admin" || type.type === "business") && <a href="/admin/analytics/overview">Admin</a>}

											{(type.type !== "business" && type.editor === "approved") && <a href="/admin/news">Admin</a>}

											{(type.type === "influencer") && <a href="/admin/analytics">Admin</a>}


										</div>

									</div>
								</div>

							</div>

							<div className="desktop_right">
								<div className="header_header_right_items">
									<div autoSave="false" className="home_search_form">
										<div className="icon_right">
											<div className="heart notification" ref={notifyRef}>
												<div className="heart_top" onClick={notificationHandler}>
													{type.notifyLength > 0 && (
														<span className="badge badge-danger badge_item notify_bage">
															{type.notifyLength}
														</span>
													)}
													<img src={url + '/uploads/default/icons8-bell-20.png'} alt="" />
												</div>
												{openNotification &&
													<div className="heart_bottom notification_bottom" >
														<div class="notfication_list" >
															<InfiniteScroll
																loadMore={findItem}
																hasMore={hasMore}
																initialLoad={false}
															>
																{type.notify.map(item => {
																	return (
																		<div class="notification_item" onClick={() => setOpenNotification(false)}>
																			{notifyMsg(item)}
																		</div>
																	)
																})}
															</InfiniteScroll>
														</div>
													</div>
												}
											</div>
											<div className="heart">
												<div className="heart_top">
													<a onClick={loginPopupHandler}>
														<img src={url + '/uploads/default/icons8-heart-20.png'} alt="" />
													</a>
												</div>
												<div className="heart_bottom">

												</div>

											</div>
											<div className="cart">
												<div className="cart_top">
													<Link to="/cart">
														<img src={url + '/uploads/default/icons8-shopping-basket-star-20.png'} alt="" />
													</Link>
													{cartItem.length > 0 && (
														<span className="badge badge-danger badge_item cart_bage">
															{cartItem.length}
														</span>
													)}
												</div>
												<div className="cart_bottom">
													{/* Write curt popup code here */}
												</div>
											</div>
											<div className="people">
												<div className="people_top">
													<img src={url + '/uploads/default/icons8-login-20.png'} alt="" />
												</div>

												<div className="people_bottom">
													<div className="user_box">
														{type.type ?
															<>
																<Link to="/profile/edit">Profile</Link>
																<Link to="/orders">Orders</Link>
																<button onClick={setLogut}>
																	Logout
																</button>
															</>
															: <>
																<Link to="/login">Login</Link>
																<Link to="/register">Register</Link>
															</>
														}
													</div>
												</div>


											</div>
										</div>

									</div>
									{/* desktop menu  end*/}
								</div>

							</div>
						</div>
						:
						<div className="mobile_header">
							<div className="mobile_header_top">
								<div className="menu">
									<div className="menu_icon">
										<img onClick={isMenu} className="svg" src={url + "/uploads/default/bars.png"} alt="" />
									</div>

									<div className="menu_items" style={{
										display: !mobile ? 'none' : 'block'
									}}>

										<Link to="/strain">Strain</Link>
										<Link to="/dispensary">Dispensary</Link>
										<Link to="/merchandise">Merchandise</Link>
										<Link to="/news">News</Link>
										{/* <Link to="/deals">Deals</Link> */}
										<Link to="/review">Review</Link>


										{!type.type && <Link to="/influencer">Influencer </Link>}

										{type.type &&
											<div className="others_menu">
												<a onClick={submenuHandler} className="others_menu_tab">Influencer <i className={submenu ? "fa fa-angle-up" : "fa fa-angle-down"}></i></a>
												{submenu &&
													<div className="submenu">
														<div className="submneu_items">
															<Link to="/influencer/">Influencer Post</Link>
															<Link to="/influencer/my-post">My Post</Link>
														</div>
													</div>
												}

												{/* Below is admin menu */}
												{(type.type === "admin" || type.type === "business") && <a href="/admin/analytics/overview">Admin</a>}

												{(type.type !== "business" && type.editor === "approved") && <a href="/admin/news">Admin</a>}

												{(type.type === "influencer") && <a href="/admin/analytics">Admin</a>}
											</div>
										}
										{type.type &&
											<div className="others_menu">
												<a onClick={submenuHandler2} className="others_menu_tab">Social <i className={submenu2 ? "fa fa-angle-up" : "fa fa-angle-down"}></i></a>
												{submenu2 &&
													<div className="submenu">
														<div className="submneu_items">
															<Link to="/social/feed">Feed</Link>
															<Link to="/social/post">My Post</Link>
															<Link to="/social/messages">Chat</Link>
															<Link to="/social/friend-request">Friend Request</Link>
															<Link to="/social/find-friend">Find Friend</Link>
															<Link to="/social/friends">Friends</Link>
															<Link to="/social/block">Block List</Link>
														</div>
													</div>
												}

												{/* Below is admin menu */}
												{(type.type === "admin" || type.type === "business") && <a href="/admin/analytics/overview">Admin</a>}

												{(type.type !== "business" && type.editor === "approved") && <a href="/admin/news">Admin</a>}

												{(type.type === "influencer") && <a href="/admin/analytics">Admin</a>}
											</div>
										}
									</div>
								</div>
								<div className="logo">
									<Link to="/">
										<img src={url + '/uploads/default/mango.png'} alt="" />
									</Link>
								</div>

								<div className="icon_list">
									<div className="heart notification" ref={notifyRef}>
										<div className="heart_top" onClick={notificationHandler}>
											{type.notifyLength > 0 && (
												<span className="badge badge-danger badge_item notify_bage">
													{type.notifyLength}
												</span>
											)}
											<img src={url + '/uploads/default/icons8-bell-20.png'} alt="" />
										</div>
										{openNotification &&
											<div className="heart_bottom notification_bottom" >
												<div class="notfication_list" >
													<InfiniteScroll
														loadMore={findItem}
														hasMore={hasMore}
														initialLoad={false}
													>
														{type.notify.map(item => {
															return (
																<div class="notification_item">
																	{notifyMsg(item)}
																</div>
															)
														})}
													</InfiniteScroll>
												</div>
											</div>
										}
									</div>

									<div className="heart">
										<div className="heart_top">
											<a onClick={loginPopupHandler}>
												<img src={url + '/uploads/default/icons8-heart-20.png'} alt="" />
											</a>
										</div>
										<div className="heart_bottom">

										</div>
									</div>

									<div className="cart">
										<div className="cart_top">
											<Link to="/cart">
												<img src={url + '/uploads/default/icons8-shopping-basket-star-20.png'} alt="" />
											</Link>
										</div>
										<div className="cart_bottom">

										</div>

									</div>
									<div className="people">
										<div className="people_top">
											<img src={url + '/uploads/default/icons8-login-20.png'} alt="" />
										</div>

										<div className="people_bottom">
											<div className="user_box">
												{type.type ?
													<>
														<Link to="/profile/edit">Profile</Link>
														<Link to="/orders">Orders</Link>
														<button onClick={setLogut}>
															Logout
														</button>
													</>
													: <>
														<Link to="/login">Login</Link>
														<Link to="/register">Register</Link>
													</>
												}
											</div>
										</div>
									</div>
								</div>

							</div>
							<div className="mobile_header_bottom">
								<SearchBar corProp={cordinates} placeProp={currentPlace} />

								{/* <Form onSubmit={search} autoSave="false" autoComplete="false" method="post" className="home_search_form">
									<div className="address_input">
										<Autocomplete
											className=""
											options={{
												// types: ['geocode',],
												// (regions)
												// 'address'
												types: ["(regions)"],
												componentRestrictions: { country: "us" },
											}}
											apiKey={process.env.REACT_APP_MAP_KEY}
											onPlaceSelected={placeController}
											onChange={addressChanger}
											value={currentPlace}
											placeholder="Addrss"
										/>
									</div>

									<div className="search_input">
										<div className="input_left">
											<input required onChange={searchHandler} name="search" type="search" placeholder="Search" className="home_search_input" value={searchText} />
										</div>
										<div className="input_right">
											<input type="image" src={url + '/uploads/default/icons8-search-20.png'} alt="Submit" />
										</div>
									</div>
								</Form> */}
							</div>
						</div>
					}
				</div>
			</div >
		</>
	)
}
export default Header
